import Vue from 'vue'
import Router from 'vue-router'
import login from '../pages/login/Login'
import firebase from 'firebase'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: 'Entrar',
      public: true
    },
    component: login
  },
  {
    path: '/login/:email?/:password?',
    name: 'login',
    meta: {
      title: 'Entrar',
      public: true
    },
    component: login
  },
  {
    path: '/management/',
    name: 'management',
    meta: {
      title: 'Gestão'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/management/Management')
  },
  {
    path: '/users/:searchValue?',
    name: 'users',
    meta: {
      title: 'Clientes'
    },
    component: () => import(/* webpackChunkName: "Login" */ '../pages/users/Users')
  },
  {
    path: '/home/',
    name: 'home',
    meta: {
      title: 'Resumo'
    },
    component: () => import(/* webpackChunkName: "Home" */ '../pages/home/Home')
  },
  {
    path: '/leads/:searchValue?',
    name: 'leads',
    meta: {
      title: 'Leads'
    },
    component: () => import(/* webpackChunkName: "HotLeads" */ '../pages/hotleads/Leads')
  },
  {
    path: '/simulator',
    name: 'simulador',
    meta: {
      title: 'Simulador'
    },
    component: () => import(/* webpackChunkName: "HotLeads" */ '../pages/hotleads/Simulator/SimulatorPlans.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      title: 'Dashboard'
    },
    component: () => import(/* webpackChunkName: "Dashboard" */ '../pages/dashboard/Dashboard.vue')
  }
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'SimplesHub | ' + to.meta.title
  document.public = to.meta.public
  if (to.matched.some(record => !record.meta.public)) {
    if (!firebase.auth().currentUser) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }

  // if (!window.uid && !to.meta.public) {
  //   next({ name: 'login' })
  // } else {
  //   next()
  // }
})

export default router
