import Vue from "vue";
import moment from "moment";

Vue.directive("money-format", (element, { value }) => {
  element.innerHTML = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
    minimumFractionDigits: 2,
  }).format(value || 0);
});

Vue.directive("date-format", (element, { value }) => {
  element.innerHTML = moment(value).format("DD/MM/YYYY [às] HH:mm:ss");
});
Vue.directive("date-format-only", (element, { value }) => {
  element.innerHTML = moment(value).format("DD/MM/YYYY");
});

function formatCPFOrCNPJ(value) {
  const cleanedValue = value.replace(/\D/g, "");

  if (cleanedValue.length === 11) {
    // Formatar como CPF: 123.456.789-00
    return cleanedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (cleanedValue.length === 14) {
    // Formatar como CNPJ: 12.345.678/0001-90
    return cleanedValue.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }

  // Retornar o valor original se não tiver o formato esperado
  return value;
}

// Diretiva para formatação de CPF/CNPJ
Vue.directive("cpf-cnpj-format", (element, { value }) => {
  if (value) {
    element.innerHTML = formatCPFOrCNPJ(value);
  } else {
    element.innerHTML = "";
  }
});

function formatPhoneNumber(value) {
  const cleanedValue = value.replace(/\D/g, "");

  // Formatar números com DDD e 9 dígitos (celular): (11) 91234-5678
  if (cleanedValue.length === 11) {
    return cleanedValue.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }

  // Formatar números com DDD e 8 dígitos (fixo): (11) 1234-5678
  if (cleanedValue.length === 10) {
    return cleanedValue.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }

  // Formatar números sem DDD (celular): 91234-5678
  if (cleanedValue.length === 9) {
    return cleanedValue.replace(/(\d{5})(\d{4})/, "$1-$2");
  }

  // Formatar números sem DDD (fixo): 1234-5678
  if (cleanedValue.length === 8) {
    return cleanedValue.replace(/(\d{4})(\d{4})/, "$1-$2");
  }

  // Retornar o valor original se não tiver o formato esperado
  return value;
}

// Diretiva para formatação de telefone
Vue.directive("phone-format", (element, { value }) => {
  if (value) {
    element.innerHTML = formatPhoneNumber(value);
  } else {
    element.innerHTML = "";
  }
});
